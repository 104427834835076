import {
  Team,
  TeamInsert,
  TeamWithProfiles
} from '@dolfin/business/custom_types'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { QueryKeys } from '@/hooks/queryKeys'
import { showToastError, showToastSuccess } from '@/utils/messages'
import { t } from '@lingui/macro'

const useTeams = ({
  enabled = true,
  onSuccess,
  onError,
  setLoading,
  afterInsert
}: {
  enabled?: boolean
  onSuccess?: (profile: TeamWithProfiles[]) => void
  onError?: (error: Error) => void
  setLoading?: (b: boolean) => void
  afterInsert?: (team: Team) => void
}) => {
  const supabase = useSupabaseClient()
  const queryClient = useQueryClient()

  const fetchTeams = async () => {
    const { data: teams, error } = await supabase
      .from('teams')
      .select('*, profiles(*)')
      .order('created_at', { ascending: true })

    if (error) throw error

    return teams
  }

  const { data: teams, isLoading } = useQuery(QueryKeys.teams.get, fetchTeams, {
    onError,
    onSuccess,
    enabled
  })

  const insertTeam = useMutation(
    async (team: TeamInsert) => {
      const { data, error } = await supabase
        .from('teams')
        .insert([team])
        .select('*')
        .single()

      if (error) throw error

      setLoading?.(false)

      return data
    },
    {
      onSuccess: team => {
        setLoading?.(false)
        showToastSuccess('Team created')
        queryClient.invalidateQueries(QueryKeys.teams.get)

        if (afterInsert) afterInsert(team as unknown as Team)
      },
      onError: (e: any) => {
        setLoading?.(false)
        showToastError(new Error(t`Error creating the team: `, e))
      }
    }
  )

  const deleteTeam = useMutation(
    async (id: number) => {
      const { data, error } = await supabase.from('teams').delete().eq('id', id)

      if (error) throw error

      return data
    },
    {
      onSuccess: () => {
        showToastSuccess('Team deleted')
        queryClient.invalidateQueries(QueryKeys.teams.get)
      },
      onError: (e: any) => {
        showToastError(new Error(t`Error deleting the team: `, e))
      }
    }
  )

  const updateTeam = useMutation(
    async (team: Pick<Team, 'id' | 'name'>) => {
      const { data, error } = await supabase
        .from('teams')
        .update({ name: team.name })
        .eq('id', team.id)

      if (error) throw error

      return data
    },
    {
      onSuccess: () => {
        showToastSuccess('Team updated')
        queryClient.invalidateQueries(QueryKeys.teams.get)
      },
      onError: (e: any) => {
        showToastError(new Error(t`Error updating the team: `, e))
      }
    }
  )

  const addProfileToTeam = useMutation(
    async (relations: { team_id: number; profile_id: string }[]) => {
      const { data, error } = await supabase
        .from('teams_profiles')
        .insert(relations)

      if (error) throw error

      return data
    },
    {
      onSuccess: () => {
        showToastSuccess('Profile added to team')
        queryClient.invalidateQueries(QueryKeys.teams.get)
      },
      onError: (e: any) => {
        showToastError(new Error(t`Error adding the profile to the team: `, e))
      }
    }
  )

  return {
    teams,
    isLoading,
    insertTeam,
    deleteTeam,
    updateTeam,
    addProfileToTeam
  }
}

export default useTeams
