import withI18n from '@/utils/withTranslations'
import { t, Trans } from '@lingui/macro'
import { Controller, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { showToastError, showToastSuccess } from '@/utils/messages'
import {
  useSignInWithOAuth,
  useSignInWithPassword,
  useSignInWithSSO
} from '@/hooks/auth'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { invalidateNextRouterCache } from '@/utils/invalidateNextRouterCache'
import { ButtonLink, Heading } from '@/src/ui'
import { TextField } from '@/src/stories/TextField'
import { Button } from '@/src/stories/Button'
import { Database } from '@dolfin/business/db_types'
import { HeadingSizes, HeadingWeights } from '@/src/ui/typography/heading'
import { Form } from 'react-aria-components'
import { ArrowRight } from '@untitled-ui/icons-react'

type FormProps = {
  email: string
  password: string
}

const FormLogin = () => {
  const supabase = useSupabaseClient<Database>()
  const router = useRouter()
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      remember: true,
      email: '',
      password: ''
    }
  })

  const [showSSO, setShowSSO] = useState(false)

  useEffect(() => {
    const listener = supabase.auth.onAuthStateChange(event => {
      if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
        invalidateNextRouterCache()
        const page = router.query.page
        if (page) {
          router.replace(`/dashboard?page=${page}`)
        } else {
          router.replace('/dashboard')
        }
      }
    })

    return listener.data.subscription.unsubscribe
  }, [router, supabase.auth])

  const sendMagicLink = async () => {
    async function signInWithEmail() {
      return await supabase.auth.signInWithOtp({
        email: watch('email'),
        options: {
          // set this to false if you do not want the user to be automatically signed up
          shouldCreateUser: false,
          emailRedirectTo: window.location.origin + '/dashboard'
        }
      })
    }

    const { error } = await signInWithEmail()

    if (!error) {
      showToastSuccess(
        t`We've sent you a magic link to sign in. Please check your email.`
      )
    }
  }

  const signIn = useSignInWithPassword(async () => null, showToastError)

  const signInWithOAuth = useSignInWithOAuth(async () => null, showToastError)

  const signInSSO = useSignInWithSSO(
    watch('email'),
    async () => null,
    showToastError
  )

  const onSubmit = (data: FormProps) => {
    signIn.mutate(data)
  }

  return (
    <div className='flex flex-col gap-8 w-full sm:w-auto min-w-[40vw] lg:min-w-[30vw] xl:min-w-[20vw]'>
      {!showSSO ? (
        <>
          <Heading
            level={2}
            size={HeadingSizes['2XL']}
            weight={HeadingWeights.FONT_MEDIUM}
            className={'text-center sm:text-left'}>
            <Trans>Welcome back!</Trans>
          </Heading>
          <Form
            className='flex flex-col w-full gap-6'
            onSubmit={handleSubmit(
              !watch('password') ? sendMagicLink : onSubmit
            )}>
            <input defaultValue='true' type='hidden' />
            <Controller
              control={control}
              name='email'
              rules={{ required: 'Email invalid or missing' }}
              render={({
                field: { name, value, onChange, onBlur },
                fieldState: { invalid, error }
              }) => (
                <TextField
                  placeholder={t`Your email`}
                  errorMessage={error?.message}
                  name={name}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  type='email'
                  isRequired
                  validationBehavior='aria'
                  isInvalid={invalid}></TextField>
              )}
            />
            <Controller
              control={control}
              name='password'
              render={({
                field: { name, value, onChange, onBlur },
                fieldState: { invalid, error }
              }) => (
                <TextField
                  placeholder={t`Your password`}
                  errorMessage={error?.message}
                  name={name}
                  value={value}
                  type='password'
                  onChange={onChange}
                  onBlur={onBlur}
                  validationBehavior='aria'
                  isInvalid={invalid}></TextField>
              )}
            />

            <div className='flex align-center justify-end'>
              <ButtonLink href='/forgot-password'>
                <Trans>Forgot your password?</Trans>
              </ButtonLink>
            </div>
            <div className={'space-y-4 flex flex-col items-center w-full'}>
              <Button
                isDisabled={!watch('email')}
                className={'w-full justify-center'}
                label={
                  watch('password') ? t`Sign In` : t`Login without password`
                }
                type='submit'
              />
            </div>
          </Form>

          <div className='flex items-center gap-2'>
            <span className={`h-px flex flex-1 bg-slate-200 my-2`}></span>

            <p
              className={`text-slate-500 text-sm font-medium flex items-center justify-center`}>
              <Trans>or continue with</Trans>
            </p>

            <span className={`h-px flex flex-1 bg-slate-200 my-1`}></span>
          </div>

          <div className='flex flex-col gap-4'>
            <Button
              iconLeading={
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 186.69 190.5'>
                  <g transform='translate(1184.583 765.171)'>
                    <path
                      clipPath='none'
                      mask='none'
                      d='M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z'
                      fill='#4285f4'
                    />
                    <path
                      clipPath='none'
                      mask='none'
                      d='M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z'
                      fill='#34a853'
                    />
                    <path
                      clipPath='none'
                      mask='none'
                      d='M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z'
                      fill='#fbbc05'
                    />
                    <path
                      d='M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z'
                      fill='#ea4335'
                      clipPath='none'
                      mask='none'
                    />
                  </g>
                </svg>
              }
              className={
                'flex flex-1 h-10 justify-center text-sm font-[Roboto] font-medium'
              }
              hierarchy={'secondary'}
              label={t`Sign In with Google`}
              onPress={() => signInWithOAuth.mutate('google')}
            />

            <Button
              className={'flex flex-1 h-10 justify-center text-sm'}
              hierarchy={'secondary'}
              label={t`Single Sign-On`}
              onPress={() => setShowSSO(!showSSO)}
            />
          </div>

          <div
            id='g_id_onload'
            data-client_id='155793983810-ibgnilda5um69n2d9stnaqt1rs9l7g0e.apps.googleusercontent.com'
            data-context='signin'
            data-ux_mode='popup'
            data-callback='handleSignInWithGoogle'
            data-itp_support='true'
            data-use_fedcm_for_prompt='true'></div>
        </>
      ) : (
        <div className={'flex flex-col gap-6'}>
          <Heading
            level={2}
            size={HeadingSizes['2XL']}
            weight={HeadingWeights.FONT_MEDIUM}>
            <Trans>Single Sign-On</Trans>
          </Heading>
          <Controller
            control={control}
            name='email'
            rules={{ required: 'Email invalid or missing' }}
            render={({
              field: { name, value, onChange, onBlur },
              fieldState: { invalid, error }
            }) => (
              <TextField
                placeholder={t`Your email`}
                errorMessage={error?.message}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type='email'
                isRequired
                validationBehavior='aria'
                isInvalid={invalid}></TextField>
            )}
          />
          <div className='flex flex-col gap-6'>
            <Button
              className={'flex justify-center'}
              label={t`Continue`}
              onPress={() => signInSSO.mutate()}
              iconTrailing={<ArrowRight width={16}></ArrowRight>}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export const getServerSideProps = withI18n(ctx => {
  return {
    props: { translation: ctx.translation, locale: ctx.locale }
  }
})

export default FormLogin
