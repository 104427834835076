import { useMutation, useQuery } from 'react-query'
import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { Database } from '@dolfin/business/db_types'
import { useRouter } from 'next/router'
import { Factor } from '@supabase/gotrue-js'
import posthog from 'posthog-js'

interface MFAVerifyData {
  factorId: string
  challengeId: string
  code: string
}

export const useSignup = (
  onSuccess: () => void,
  onError: (error: Error) => void
) => {
  const supabase = useSupabaseClient<Database>()

  return useMutation(
    'signup',
    async (credentials: { email: string; name: string; password: string }) => {
      const { error } = await supabase.auth.signUp({
        email: credentials.email,
        password: credentials.password,
        options: {
          emailRedirectTo: `${process.env.NEXT_PUBLIC_BASE_URL}/signup/email-confirmed`,
          data: {
            name: credentials.name
          }
        }
      })

      if (error) {
        throw error
      }
    },
    { onError, onSuccess }
  )
}

export const useAcceptInvite = (
  onSuccess: () => void,
  onError: (error: Error) => void
) => {
  const supabase = useSupabaseClient<Database>()

  return useMutation(
    'accept_invite',
    async (credentials: {
      accessToken: string
      refreshToken: string
      password: string
    }) => {
      await supabase.auth.setSession({
        access_token: credentials.accessToken,
        refresh_token: credentials.refreshToken
      })

      const { error } = await supabase.auth.updateUser({
        password: credentials.password
      })

      if (error) {
        throw error
      }
    },
    {
      onError,
      onSuccess
    }
  )
}

export const useSignOut = () => {
  const router = useRouter()
  const supabase = useSupabaseClient<Database>()

  return async () => {
    posthog.reset()
    const { error } = await supabase.auth.signOut()

    if (error) {
      throw error
    }

    router.push('/login')
  }
}

export const useSignInWithPassword = (
  onSuccess: () => void,
  onError: (error: Error) => void
) => {
  const supabase = useSupabaseClient<Database>()

  return useMutation(
    'signin',
    async (credentials: { email: string; password: string }) => {
      const { data, error } = await supabase.auth.signInWithPassword(
        credentials
      )

      if (error) {
        throw error
      }

      return data
    },
    { onError, onSuccess }
  )
}

export const useSignInWithOAuth = (
  onSuccess: () => void,
  onError: (error: Error) => void
) => {
  const supabase = useSupabaseClient<Database>()

  return useMutation(
    'signin',
    async (provider: 'google') => {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider
      })

      if (error) {
        throw error
      }

      return data
    },
    { onError, onSuccess }
  )
}

export const useSignInWithSSO = (
  email: string,
  onSuccess: () => void,
  onError: (error: Error) => void
) => {
  const supabase = useSupabaseClient<Database>()

  return useMutation(
    'signin',
    async () => {
      const { data, error } = await supabase.auth.signInWithSSO({
        domain: email.split('@')[1]
      })

      if (error) {
        throw error
      }

      if (data?.url) {
        window.location.href = data.url
      }
    },
    { onError, onSuccess }
  )
}

export const useEnrollMfa = (
  onSuccess: (data: {
    id: string
    type: 'totp'
    totp: { qr_code: string; secret: string; uri: string }
  }) => void,
  onError: (error: Error) => void
) => {
  const supabase = useSupabaseClient<Database>()

  return useMutation(
    'mfaEnroll',
    async () => {
      const { error, data } = await supabase.auth.mfa.enroll({
        factorType: 'totp'
      })

      if (error) {
        throw error
      }

      return data
    },
    { onError, onSuccess }
  )
}

export const useUnenrrollMfa = (
  onSuccess: () => void,
  onError: (error: Error) => void
) => {
  const supabase = useSupabaseClient<Database>()

  return useMutation(
    'mfaUnEnroll',
    async (factorId: string) => {
      const { error, data } = await supabase.auth.mfa.unenroll({
        factorId
      })

      if (error) {
        throw error
      }

      return data
    },
    { onError, onSuccess }
  )
}

export const useMfaChallenge = (
  onSuccess: (data: { id: string; expires_at: number } | null) => void,
  onError: (error: Error) => void
) => {
  const supabase = useSupabaseClient<Database>()

  return useMutation(
    'mfaChallenge',
    async (factorId: string) => {
      const { error, data } = await supabase.auth.mfa.challenge({
        factorId
      })

      if (error) {
        throw error
      }

      return data
    },
    { onError, onSuccess }
  )
}

export const useMfaVerify = (
  onSuccess: () => void,
  onError: (error: Error) => void
) => {
  const supabase = useSupabaseClient<Database>()

  return useMutation(
    'mfaVerify',
    async (data: MFAVerifyData) => {
      const { error } = await supabase.auth.mfa.verify(data)

      if (error) {
        throw error
      }
    },
    { onError, onSuccess }
  )
}

export const useMfaListFactors = (
  onSuccess: (factors: Factor[]) => void,
  onError: (error: Error) => void
) => {
  const supabase = useSupabaseClient<Database>()

  return useQuery(
    'mfaListFactors',
    async () => {
      const { data, error } = await supabase.auth.mfa.listFactors()

      if (error) {
        throw error
      }

      return data.totp
    },
    { onError, onSuccess }
  )
}
