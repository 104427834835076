import { useForm } from 'react-hook-form'
import { t, Trans } from '@lingui/macro'
import { Button, Input } from '@/src/ui'
import { Team } from '@dolfin/business/custom_types'
import { InputSizes, InputTypes } from '@/src/ui/input'
import { BtnTypes } from '@/src/ui/button'
import useTeams from '@/hooks/team'

type FormEditProps = {
  team: Team
}

const FormEditTeam = (props: FormEditProps) => {
  const { team } = props

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      id: team.id,
      name: team.name
    }
  })

  const { updateTeam, deleteTeam } = useTeams({ enabled: false })

  const onSubmit = async (team: Pick<Team, 'id' | 'name'>) => {
    updateTeam.mutate(team)
  }

  const handleOnDeleteTeam = () => {
    deleteTeam.mutate(team.id)
  }

  return (
    <div className='flex flex-col gap-16'>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col gap-3 w-full'>
        <Input
          inputType={!errors.name ? InputTypes.PRIMARY : InputTypes.DANGER}
          helperText={errors.name && t`This team's name already exists`}
          label={t`Team name` + '*'}
          inputSize={InputSizes.SMALL}
          {...register('name', { required: true })}
        />
        <div className='flex justify-end mt-4'>
          <Button type='submit' block>
            <Trans>Save</Trans>
          </Button>
        </div>
      </form>
      <div className='text-center relative'>
        <span className='absolute left-0 top-3 h-px w-full bg-primary/5' />
        <span className='bg-primary-negative text-center text-xs text-primary/40 px-4 inline-block relative z-10'>
          <Trans>or</Trans>
        </span>
      </div>
      <div className='flex flex-col gap-6'>
        <Button btnType={BtnTypes.DANGER} block onClick={handleOnDeleteTeam}>
          <Trans>Delete Team</Trans>
        </Button>
        <p className='text-center text-xs text-primary/50'>
          <Trans>This action cannot be undone</Trans>
        </p>
      </div>
    </div>
  )
}

export default FormEditTeam
